import React from 'react'
import { InputText, InputPhone, InputCountry } from 'components'
import { FormWrapper } from './styles'
import { Text } from '../../styles'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

const propTypes = {
  values: PropTypes.shape({
    name: PropTypes.shape({
      value : PropTypes.string,
      error : PropTypes.string
    }),
    phone: PropTypes.shape({
      value   : PropTypes.string,
      country : PropTypes.string,
      error   : PropTypes.string
    }),
    country: PropTypes.shape({
      value: PropTypes.shape({
        countryName  : PropTypes.string,
        name         : PropTypes.string,
        country_iso2 : PropTypes.string
      }),
      error: PropTypes.string
    }),
    postalCode: PropTypes.shape({
      value : PropTypes.string,
      error : PropTypes.string
    }),
    email: PropTypes.shape({
      value : PropTypes.string,
      error : PropTypes.string
    }),
    address: PropTypes.shape({
      value : PropTypes.string,
      error : PropTypes.string
    }),
    city: PropTypes.shape({
      value : PropTypes.string,
      error : PropTypes.string
    })
  }),
  setValues           : PropTypes.func,
  handleChange        : PropTypes.func,
  handleChangeCountry : PropTypes.func,
  handleChangePhone   : PropTypes.func,
  handleBlur          : PropTypes.func
}

const defaulProps = {
  values: {
    name: {
      value : '',
      error : ''
    },
    phone: {
      value   : '',
      country : 'id',
      error   : ''
    },
    country: {
      value: {
        countryName  : '',
        name         : '',
        country_iso2 : ''
      },
      error: ''
    },
    postalCode: {
      value : '',
      error : ''
    },
    email: {
      value : '',
      error : ''
    },
    address: {
      value : '',
      error : ''
    },
    city: {
      value : '',
      error : ''
    },
    npwp: {
      value : '',
      error : ''
    }
  },
  setValues           : () => {},
  handleChange        : () => {},
  handleChangeCountry : () => {},
  handleChangePhone   : () => {},
  handleBlur          : () => {}
}

const BillingInformationForm = ({
  values,
  setValues,
  handleChange,
  handleChangeCountry,
  handleChangePhone,
  handleBlur
}) => {
  const intl = useIntl()
  return (
    <>
      <Text shaded fontWeight="600" m="1rem 0 0.75rem">
        { intl.formatMessage({ id: 'abd4b3b29' }) }
      </Text>

      <FormWrapper>
        <InputText
          name="name"
          title={ intl.formatMessage({ id: '478df0a7f' }) }
          value={ values.name.value }
          error={ values.name.error }
          onChange={ handleChange }
          onBlur={ handleBlur }
          placeholder={ intl.formatMessage({ id: 'eff379382' }) }
        />

        <InputText
          name="email"
          title={ intl.formatMessage({ id: 'a8ebc8742' }) }
          value={ values.email.value }
          error={ values.email.error }
          onChange={ handleChange }
          onBlur={ handleBlur }
          placeholder={ intl.formatMessage({ id: 'd100971b3' }) }
          disabled={ true }
        />

        <InputPhone
          title={ intl.formatMessage({ id: '7c93abef9' }) }
          placeholder={ intl.formatMessage({ id: '23867fe93' }) }
          name="phone"
          value={ values.phone.value }
          defaultCountry={ values.phone.country_iso2 }
          onChange={ handleChangePhone }
          error={ values.phone.error }
        />

        <InputText
          name="address"
          title={ intl.formatMessage({ id: 'dddd083fe' }) }
          value={ values.address.value }
          error={ values.address.error }
          placeholder={ intl.formatMessage({ id: '9c5dd198a' }) }
          onChange={ handleChange }
          onBlur={ handleBlur }
        />

        <InputCountry
          withSearch
          keySearch="countryName"
          svgPosition={{ top: '4px' }}
          borderColor="#bcbfc8"
          py="4px"
          errorText={ values.country.error }
          selectedItem={ values.country.value }
          selectedKey="countryName"
          placeholder={ intl.formatMessage({ id: '50f9931ba' }) }
          onChange={ handleChangeCountry }
          handleSelectFlag={ (num, country) => {
            setValues((prevValue) => ({
              ...prevValue,
              phone: {
                dialCode: country.dialCode
              },
              country_iso2: country.iso2
            })) 
          } }
        />

        <InputText
          name="city"
          title={ intl.formatMessage({ id: 'cef916b2e' }) }
          value={ values.city.value }
          error={ values.city.error }
          placeholder={ intl.formatMessage({ id: '531cc5ac5' }) }
          onChange={ handleChange }
          onBlur={ handleBlur }
        />

        <InputText
          name="postalCode"
          title={ intl.formatMessage({ id: '8c8f89dfd' }) }
          value={ values.postalCode.value }
          error={ values.postalCode.error }
          placeholder={ intl.formatMessage({ id: 'f48e9fc00' }) }
          onChange={ handleChange }
          onBlur={ handleBlur }
        />
        <div>

          <InputText
            name="npwp"
            title="NPWP"
            value={ values.npwp.value }
            error={ values.npwp.error }
            placeholder={ intl.formatMessage({ id: 'Npwp-1' }) }
            onChange={ handleChange }
            onBlur={ handleBlur }
          />
        </div>
      </FormWrapper>
    </>
  )
}

BillingInformationForm.propTypes = propTypes
BillingInformationForm.defaulProps = defaulProps

export default BillingInformationForm
