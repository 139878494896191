import styled from 'styled-components'
import { snow, darkBlueGrey } from 'components/baseColor'

export const Wrapper = styled.div`
  right: 0;
  width: 28em;
  min-height: 100vh;
  background-color: ${snow};
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.25);
  padding: 0.75rem 2rem 3rem;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-row-gap: 0.5rem;
  position: sticky;
  top: 5rem;

  @media only screen and (min-width: 1024px) and (max-width: 1050px) { 
    min-height: 80vh;
  }

  > p {
    margin: 0;
  }
`

export const Title = styled.p`
  font-size: 2.3em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  text-transform: uppercase;
`

export const Hr = styled.hr`
  height: 0px;
  background-color: #0000005c;
  width: 100%;
  overflow: none;
  margin: 0;
`

export const Ul = styled.ul`
  height: 7rem;
  overflow: auto;
  list-style-type: none;
  padding: 0;
  margin: 0.25rem 0;
  padding-right: 6px;
`

export const Li = styled.li`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
