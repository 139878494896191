import { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { REG_EMAIL, REG_NUMBER } from 'components/dictionary'
import { CART_TYPES, TRANSACTION_TYPES } from 'store/types'
import API, { AccountURL, TransactionURL } from 'commons/API'
import { PAYMENT_METHOD } from '../constant'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useToasts } from 'react-toast-notifications'
import { useIntl } from 'react-intl'

const useForm = ({ selectedPaymentMethod }) => {
  const intl = useIntl()
  const { token: tokenDashboard, isLoading } = useSelector(({ tokenDashboard }) => tokenDashboard)
  const { 
    isCustom, 
    paymentPeriod, 
    selectedPackage, 
    ...cart 
  } = useSelector((state) => state.cart)
  const { selectedBank } = useSelector(({ payment }) => payment)
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [values, setValues] = useState({
    name: {
      value : '',
      error : ''
    },
    email: {
      value : '',
      error : ''
    },
    phone: {
      dialCode     : '',
      value        : '',
      country_iso2 : '',
      error        : ''
    },
    address: {
      value : '',
      error : ''
    },
    country: {
      value: {
        countryName  : '',
        name         : '',
        country_iso2 : ''
      },
      error: ''
    },
    city: {
      value : '',
      error : ''
    },
    postalCode: {
      value : '',
      error : ''
    },
    npwp: {
      value : '',
      error : ''
    }
  })

  const { 
    name, 
    email, 
    phone, 
    address, 
    country, 
    city, 
    postalCode,
    npwp
  } = values
  const [isLoadingForm, setIsLoadingForm] = useState(true)

  const reactStripe = useStripe()
  const elements = useElements()
  const [isBlurCCInput, setIsBlurCCInput] = useState(false)
  const [creditCardError, setCreditCardError] = useState('')
  const [ccHolderName, setCCHolderName] = useState({
    value : '',
    error : ''
  })

  const [isSubmit, setIsSubmit] = useState(false)

  // autofill form
  useEffect(() => {
    // check login status
    if (tokenDashboard && !isLoading) {
      API.get(AccountURL.GetCompanyDetail, {
        headers: { 
          authorization: `Bearer ${tokenDashboard.token}` 
        }
      }).then((response) => {
        const data = response.data.company

        values.name.value = data.name
        values.email.value = data.email
        values.phone.value = data.phone
        values.phone.dialCode = data.countryCode
        values.phone.country_iso2 = data.country_iso2
        values.country.value = {
          countryName  : data.country,
          name         : data.country,
          country_iso2 : data.country_iso2
        }
        values.npwp.value = data.npwp ? data.npwp : ''
  
        setValues({ ...values })
  
        const basedCountryCurrency = 'Indonesia'
        let selectedCurrency
  
        if (data.country === basedCountryCurrency) {
          selectedCurrency = cart.currencies.find((element) => element.country === basedCountryCurrency)
          dispatch({ type: CART_TYPES.SET_CURRENCY, currency: selectedCurrency.currency })
        } else {
          selectedCurrency = cart.currencies.find((element) => element.country !== basedCountryCurrency)
          dispatch({ type: CART_TYPES.SET_CURRENCY, currency: selectedCurrency.currency })
        }
      
      })
        .finally(() => setIsLoadingForm(false))
    }
    else if (!isLoading && !tokenDashboard) {
      navigate('/pricing')
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenDashboard, dispatch, isLoading, cart.currencies])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if(name === 'postalCode' || name === 'npwp') {
      if (REG_NUMBER.test(value) || value === '') {
        setValues({ ...values, [name]: { ...values[name], value: value } })
      }
    }
    else {
      setValues({ ...values, [name]: { ...values[name], value: value } })
    }
  }

  const handleChangeCountry = (country) => {
    // update currency when country was changed
    const basedCountryCurrency = 'Indonesia'
    let selectedCurrency

    if (country.countryName === basedCountryCurrency) {
      selectedCurrency = cart.currencies.find((element) => element.country === basedCountryCurrency)
    } else {
      selectedCurrency = cart.currencies.find((element) => element.country !== basedCountryCurrency)
    }
    dispatch({ type: CART_TYPES.SET_CURRENCY, currency: selectedCurrency.currency })

    setValues((prevState) => ({
      ...prevState,
      country: { ...prevState.country, value: country, error: '' }
    }))
  }

  const handleChangePhone = (isValid, phone, country) => {
    if (REG_NUMBER.test(phone) || phone === '') {
      setValues({
        ...values,
        phone: {
          dialCode     : country.dialCode,
          value        : phone.replace(/^0+/, ''),
          country_iso2 : country.iso2,
          error        : ''
        }
      })
    }
  }

  const handleBlur = (name, value) => {
    let error = ''

    if (name === 'cardHolderName') {
      // handle blur for credit card details
      if (value === '') {
        error = intl.formatMessage({ id: 'f4cf5aaf1' })
      }

      ccHolderName.error = error
      setCCHolderName({ ...ccHolderName })
    } else {
      // handle blur for billing information form
      if (value === '') {
        error = intl.formatMessage({ id: 'f4cf5aaf1' })
      } else if (name === 'name') {
        if (value.length > 50 || value.length < 3) {
          error = 'Character minimal 3 and maximal 50'
        }
      } else if (name === 'email') {
        if (!REG_EMAIL.test(value)) {
          error = 'your email is not valid'
        }
      }

      setValues({ ...values, [name]: { error: error, value: value } })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsSubmit(true)

    // billing information input validation
    Object.keys(values).map((key) => {
      if (values[key].value === '') {
        values[key].error = 'This field must not be empty.'
      } else if (key === 'name') {
        if (values[key].value.length > 50 || values[key].value.length < 3) {
          values[key].error = 'Character minimal 3 and maximal 50'
        }
      } else if (key === 'email') {
        if (!REG_EMAIL.test(values[key].value)) {
          values[key].error = 'your email is not valid'
        }
      }
      else if (key === 'postalCode') {
        if (values[key].value.length < 5 || values[key].value.length > 8) {
          values[key].error = 'Character minimal 5 and maximal 8'
        }
      }
      else if (key === 'npwp') {
        if (values[key].value.length < 4 || values[key].value.length > 16) {
          values[key].error = 'Character minimal 4 and maximal 16'
        }
      }

      setValues({ ...values })

      return ''
    })

    const isBillingFormValid = !name.error &&
                            !email.error &&
                            !country.error &&
                            !phone.error &&
                            !address.error &&
                            !city.error &&
                            !postalCode.error &&
                            name.value &&
                            email.value &&
                            country.value &&
                            phone.value &&
                            address.value &&
                            city.value &&
                            postalCode.value &&
                            npwp.value

    const payload = {}

    payload.billing_type_id = paymentPeriod.id
    payload.billing_information = {
      name         : values.name.value,
      email        : values.email.value,
      phone_number : values.phone.value,
      dial_code    : values.phone.dialCode,
      address      : values.address.value,
      city         : values.city.value,
      postal_code  : values.postalCode.value,
      country      : values.country.value.countryName, 
      country_iso2 : values.phone.country_iso2,
      npwp         : values.npwp.value
    }
    payload.referral_code = cart.referralCode
    payload.currency_id = cart.detailCurrency.id
                      
    if (isCustom) {
      // payload for Custom Plan
      const products = Object.keys(selectedPackage.products).map((key) => ({
        id       : key, // product_id
        quantity : selectedPackage.products[key].product_account_package.quantity
      }))
                      
      payload.products = products
    } 
    else {
      // payload for non Custom Plan
      payload.account_package_id = selectedPackage.id
    }
    
    if(selectedPaymentMethod === PAYMENT_METHOD.VIRTUAL_ACCOUNT) {
      handleSubmitVA({ 
        isBillingFormValid,
        payload 
      })
    }
    else {
      handleSubmitCC({ 
        isBillingFormValid,
        payload 
      })
    }
  }

  const handleSubmitVA = async ({ 
    isBillingFormValid,
    payload 
  }) => {
    payload.bank_id = selectedBank.id

    if (isBillingFormValid) {
      await API.post(TransactionURL.PostVAClientSecret, 
        { bank_alias: selectedBank.brand_alias }, 
        {
          headers: {
            authorization: `Bearer ${tokenDashboard.token}`
          }
        })
        .then((response) => {
          const stripeClientSecret = response.data.stripe_client_secret
          const stripePaymentMetod = response.data.stripe_payment_method

          return {
            stripeClientSecret,
            stripePaymentMetod
          }
        })
        .then(async ({ stripeClientSecret, stripePaymentMetod }) => {
          if(stripeClientSecret) {
            // eslint-disable-next-line no-undef
            const stripeBeta = Stripe(process.env.STRIPE_BANK_TRANSFER_PUBLISHABLE_KEY,
              { betas: ['id_bank_transfer_beta_1'] }
            )
        
            const  { setupIntent, error } =  await stripeBeta.confirmIdBankTransferSetup(
              stripeClientSecret,
              {
                payment_method: {
                  id_bank_transfer: {
                    bank: selectedBank.brand_alias
                  },
                  billing_details: {
                    email : values.email.value,
                    name  : values.name.value
                  }
                }
              }
            )
        
            if(setupIntent) {
              const paymentMethod = setupIntent.payment_method
              payload.stripe_payment_method_id = paymentMethod
            }
            else if(error) addToast(error.message, { appearance: 'warning' })
          } 
          else if(stripePaymentMetod) {
            payload.stripe_payment_method_id = stripePaymentMetod
          }

          return payload
        })
        .then((payload) => API.post(
          TransactionURL.PostPlaceOrderVA, 
          payload,
          {
            headers: {
              authorization: `Bearer ${tokenDashboard.token}`
            }
          }
        ))
        .then((response) => {
          const data = response.data.transaction
          dispatch({ type: TRANSACTION_TYPES.SET_INVOICE, data })
          setIsSubmit(false)
          navigate(data.hosted_invoice_url)
        })
        .catch((error) =>{
          setIsSubmit(false)
          if(error.response?.data?.message) addToast(error.response.data.message, { appearance: 'warning' })
          else addToast(error.message, { appearance: 'warning' })
        } )
    } 
    else { 
      setIsSubmit(false) 
      window.scrollTo(0,0)
    }
  }

  const handleSubmitCC = async ({ 
    isBillingFormValid,
    payload 
  }) => {

    // cc form input validation
    if(!ccHolderName.value) {
      ccHolderName.error = 'This field must not be empty.'
      setCCHolderName({ ...ccHolderName })
    }
    if(!isBlurCCInput) setCreditCardError('Please complete all fields')
    
    const isCCFormValid = !creditCardError && 
                          !ccHolderName.error &&
                          ccHolderName.value

    if (!reactStripe|| !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return '' 
    }
                          
    const cardElement = elements.getElement(CardElement)

    if (isBillingFormValid && isCCFormValid ) {
      // Use your card Element with other Stripe.js APIs
      await reactStripe.createPaymentMethod({
        type            : 'card',
        card            : cardElement,
        billing_details : {
          name    : ccHolderName.value,
          email   : values.email.value,
          phone   : values.phone.value,
          address : {
            line1       : values.address.value,
            city        : values.city.value,
            postal_code : values.postalCode.value
          }
        }
      })
        .then((result) => {
          if (result.error) {
            setIsSubmit(false) 
            addToast(result.error.message, { appearance: 'warning' })
          } 
          else {
            createSubscription({
              paymentMethodId: result.paymentMethod.id,
              payload
            })
          }
        })
    }
    else { 
      setIsSubmit(false) 
      window.scrollTo(0,0)
    }
  }

  const createSubscription = ({
    paymentMethodId,
    payload
  }) => {

    payload.stripe_payment_method_id = paymentMethodId  

    API.post(
      TransactionURL.PostCreateSubscriptionCC,
      payload,
      {
        headers: {
          authorization: `Bearer ${tokenDashboard.token}`
        }
      }
    )
      .then((result) => {
        if (result.error) {
          setIsSubmit(false) 
          throw result
        }
        else {
          onSubscriptionComplete(result)
        }
      })
      .catch((error) => {
        setIsSubmit(false) 
        addToast(error.response.data.message, { appearance: 'warning' })
      })
  }

  const onSubscriptionComplete = (result) => {
    if (result.status === 200) {
      const transaction = result.data.transaction
      setIsSubmit(false) 
      dispatch({ type: TRANSACTION_TYPES.SET_INVOICE, transaction })
      navigate(`/payment/status/?transaction_id=${transaction.id}`)
    }
  }

  return {
    isLoadingForm,
    values,
    creditCardError,
    setCreditCardError,
    isSubmit,
    setValues,
    handleChange,
    handleChangeCountry,
    handleChangePhone,
    handleBlur,
    handleSubmit,
    ccHolderName,
    setCCHolderName,
    setIsBlurCCInput
  }
}

export default useForm
