import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Select } from 'components'
import { Alert, Text } from '../../styles'
import { PAYMENT_TYPES } from 'store/types'
import API, { TransactionURL } from 'commons/API'

const BankTransfer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const payment = useSelector(({ payment }) => payment)

  const [bankList, setBankList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedBanks, setSelectedBank] = useState(payment.selectedBank)

  useEffect(() => {

    const getBankList = async () => {
      await API.get(TransactionURL.GetBankList)
        .then((response) => {
          const data = response.data.banks
          const formatBankOptions = data.map((bank) => ({
            id          : bank.id,
            name        : bank.brand,
            brand_alias : bank.brand_alias 
          }))
          setBankList(formatBankOptions)

          if(!payment.selectedBank) {
            dispatch({ type: PAYMENT_TYPES.SET_BANK, selectedBank: formatBankOptions[0] })
            setSelectedBank(formatBankOptions[0])
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false))
    }

    getBankList()

  }, [dispatch, payment.selectedBank])

  const handleChangeSelectedBank = (bank) => {
    setSelectedBank(bank)
    dispatch({ type: PAYMENT_TYPES.SET_BANK, selectedBank: bank })
  }

  return (
    <>
      <span style={{ color: '#222b49' }}> { intl.formatMessage({ id: 'c5e7a8346' }) }</span>
      <Select
        isLoading={ isLoading }
        withSearch
        keySearch="name"
        svgPosition={{ top: '5px' }}
        borderColor="#bcbfc8"
        py="4px"
        options={ bankList }
        selectedItem={ selectedBanks }
        selectedKey="name"
        onChange={ handleChangeSelectedBank }
      />

      <Alert color="rgba(34, 43, 73, 0.1)" style={{ marginTop: '1.5rem' }}>
        <Text letterSpacing="0.29px">
          { intl.formatMessage({ id: 'c5e7a8346' }) }
        </Text>
        <Text letterSpacing="0.29px">
          { intl.formatMessage({ id: 'df5cb7482' }) }
        </Text>
      </Alert>
    </>
  )
}

export default BankTransfer
