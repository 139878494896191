import { useMemo } from 'react'

export const PaymentCalculation = ({
  subTotal,
  discount,
  vat
}) => {

  const discountAmount = useMemo(() => subTotal*(discount)/100, [discount, subTotal])
  const vatAmount = useMemo(() => (subTotal - discountAmount) * (vat / 100), [discountAmount, subTotal, vat])
  const grandTotalAmount = useMemo(() => (subTotal - discountAmount + vatAmount), [subTotal, discountAmount, vatAmount])

  return {
    discountAmount,
    vatAmount,
    grandTotalAmount
  }
}
