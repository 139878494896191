import React from 'react'
import Layout from 'components/Layout'
import PaymentView from 'domains/Payment'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import PropTypes from 'prop-types'

const propTypes = {
  location: PropTypes.object
}

const defaultProps = {
  location: {}
}

const Payment = ({ location }) => {

  let stripePromise
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.STRIPE_CC_PUBLISHABLE_KEY)
    }
    return stripePromise
  }

  return(
    <Elements stripe={ getStripe() }>
      <Layout>
        <PaymentView location={ location } />
      </Layout>
    </Elements>
  )
}

Payment.propTypes = propTypes
Payment.defaultProps = defaultProps

export default Payment
