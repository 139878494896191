import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import API, { TransactionURL } from 'commons/API'
import { PAYMENT_TYPES } from 'store/types'
import { PAYMENT_METHOD } from '../constant'

const usePaymentMethod = () => {
  const { token: tokenDashboard, isLoading: isLoadingToken }= useSelector(({ tokenDashboard }) => tokenDashboard)
  const { ...cart } = useSelector((state) => state.cart)
  const currency = cart.detailCurrency
  const dispatch = useDispatch()

  const [paymentMethod, setPaymentMethod] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (tokenDashboard && !isLoadingToken) {
      API.post(TransactionURL.PostPaymentMethod,
        {  currency_id: currency.id },
        { headers: { authorization: `Bearer ${tokenDashboard.token}` } }
      )
        .then((response) => {
          const data = response.data.payment_methods
          setPaymentMethod(data)
          setIsLoading(false)
          dispatch({
            type          : PAYMENT_TYPES.SET_PAYMENT_METHOD,
            paymentMethod : PAYMENT_METHOD.CREDIT_CARD
          })

        })
        .catch((error) => console.log(error))
    }
    
  }, [tokenDashboard, currency, dispatch, isLoading, isLoadingToken])

  return {
    paymentMethod,
    isLoading
  }
}

export default usePaymentMethod
