import styled from 'styled-components'
import { warmGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const MobilePaymentSummaryWrapper = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
`

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 0.5rem;
  width: 100%;
  align-items: center;

  input {
    width: 80%;
  }

  button {
    padding-left: 0;
    padding-right: 0;
  }

  ${IS_FRAME_TABLET} {
    grid-template-columns: 2fr 0.5fr;
    grid-column-gap: 3rem;

    input {
      width: 100%;
    }

    p:nth-child(2) {
      text-align: right;
    }
  }

  ${IS_FRAME_MOBILE} {
    grid-template-columns: 1.5fr 0.5fr;
    grid-column-gap: 1.5rem;

    input {
      width: 100%;
    }

    p:nth-child(2) {
      text-align: right;
    }
  }
`

export const Hr = styled.hr`
  background-color: ${warmGrey};
  height: 1px;
  width: 100%;
  margin: 0.5em 0;
`
