import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;

  ${IS_FRAME_TABLET} {
    display: flex;
    flex-direction: column;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    flex-direction: column;
  }
`
