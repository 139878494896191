/* eslint-disable no-nested-ternary */
import { coolGrey, darkBlueGrey, greenColor, disabledColor, mykonosBlue } from 'components/baseColor'
import styled, { css } from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  grid-column-gap: 3em;
  min-height: calc(100vh - 8em);

  @media only screen and (min-width: 1024px) and (max-width: 1050px) { 
    grid-column-gap: 0;
    min-height: unset;
  }

  ${IS_FRAME_TABLET} {
    display: flex;
    flex-direction: column;
    min-height: unset;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    flex-direction: column;
    min-height: unset;
  }
`

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  text-transform: uppercase;
  margin-bottom: 0;

  ${IS_FRAME_MOBILE} {
    font-size: 1.25rem;
  }
`

export const FormCard = styled.div`
  padding: 1rem 2rem 3rem;
  height: inherit;
  margin: 1rem 5rem 2rem;
  box-shadow: 0 0 40px 0 rgba(112, 144, 176, 0.2);
  background-color: #ffffff;
  border: 1px solid #bcbfc8;
  border-radius: 0.5rem;

  @media only screen and (min-width: 1024px) and (max-width: 1050px) { 
    width: calc(100vw - 40em);
    padding: 1rem 1rem 3rem;
    margin: 1rem auto 2rem;
  }

  ${IS_FRAME_TABLET} {
    padding: 1rem 1.5rem;
    margin: 2rem 1rem;
    box-shadow: none;
    border-radius: 8px;
    border: solid 1px #bcbfc8;
  }

  ${IS_FRAME_MOBILE} {
    padding: 1rem;
    margin: 2rem 1rem;
    box-shadow: none;
    border-radius: 8px;
    border: solid 1px #bcbfc8;
  }
`

export const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
  color: #000;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  margin: ${({ m }) => m};
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    font-size: ${({ fontSizeMobile }) => fontSizeMobile || '0.75rem'};
    margin: ${({ marginMobile }) => marginMobile || '0.5rem 0'};
  }

  ${({ shaded }) => shaded &&
    css`
      color: ${coolGrey};
      font-size: 0.95rem;

      ${IS_FRAME_MOBILE} {
        font-size: 0.875rem;
      }
    `}

  ${({ bold }) => bold &&
    css`
      font-weight: bolder;
    `}

  ${({ success }) => success &&
    css`
      color: ${greenColor};
      font-weight: bold;
    `}
`

Text.Referral = styled.div`
  color: ${coolGrey};
  font-size: 0.95rem;
  margin-top: 1rem;

  ${IS_FRAME_MOBILE} {
    font-size: 0.875rem;
  }
`

export const FormWrapper = styled.form`
  display: flex;
  justify-content: space-between;

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
  }
`

export const Alert = styled.div`
  padding: 0.25rem 1rem;
  border-radius: 0.4rem;
  background-color: ${({ color }) => color};
  display: block;
  margin: ${({ margin }) => margin};

  ${IS_FRAME_MOBILE} {
    margin: ${({ marginMobile }) => marginMobile};
  }
`

export const Flex = styled.div`
  display: flex;
  margin: ${({ m }) => m};

  ${({ justifyContentCenter }) => justifyContentCenter &&
    css`
      justify-content: center;
    `}

  ${({ alignItemsCenter }) => alignItemsCenter &&
    css`
      align-items: center;
    `}
`

export const PaymentInformationWrapper = styled.div`
  margin: 1rem 0;
`

export const PaymentMethodBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 1.5rem;
`

export const PaymentMethodBox = styled.div`
  width: 43%;
  background-color: ${({ isActive }) => (isActive ? mykonosBlue : '#ffffff')};
  color: ${({ isActive, isDisabled }) => isActive ? '#ffffff' : isDisabled ? disabledColor : darkBlueGrey};
  border: solid 1px #bcbfc8;
  border-radius: 0.25rem;
  padding: 1rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.35' : '1')};

  ${IS_FRAME_MOBILE} {
    width: 45%;
    padding: 0.5rem 0.25rem;
    white-space: nowrap;
    font-size: 12px;
  }
`

export const ButtonWrapper = styled.div`
  height: 3rem;
  font-size: 1rem;
  margin-top: 2.5rem;

  ${IS_FRAME_TABLET} {
    height: 2.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  ${IS_FRAME_MOBILE} {
    height: 2.5rem;
    font-size: 0.75rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`
