import styled from 'styled-components'
import { errorValidation } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const CreditCardFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex: 0 0 47%;

    :nth-child(2) {
      flex: 0 0 47%;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1050px) { 
    > div {
      flex: 0 0 40%;

      :nth-child(2) {
        flex: 0 0 55%;
      }
    }
  }

  ${IS_FRAME_TABLET} {
    flex-direction: column;
  }

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
  }
`

export const CardDetailWrapper = styled.div`
  margin-bottom: 0.2rem;
  flex: 0 0 55%;
`

export const CardInput = styled.div`
  border: solid 1px ${({ creditCardError }) => creditCardError ? errorValidation : '#bcbfc8'};
  padding: 6px 0 6px 10px;
  border-radius: 2px;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
`

export const ErrorText = styled.div`
  font-size: 0.75rem;
  color: ${errorValidation};
  display: ${({ display }) => display};
`

export const Col = styled.div`
  flex: 0 0 40%;
`
