import React, { 
  useEffect, 
  useMemo, 
  useRef, 
  useState,
  useCallback 
} from 'react'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import {  useIntl } from 'react-intl'
import {
  IconBankTransferDarkBlue,
  IconBankTransferWhite,
  IconCreditCardDarkBlue,
  IconCreditCardWhite,
  IconInfo
} from '@qlue/icon-kit'
import { 
  Button, 
  MobileOnly, 
  DesktopOnly, 
  Timeline, 
  Shimmer
} from 'components'
import {
  BillingInformationForm,
  DesktopPaymentSummary,
  MobilePaymentSummary,
  BankTransfer,
  CreditCardForm
} from './components'
import {
  Wrapper,
  FormCard,
  Title,
  Text,
  PaymentMethodBoxWrapper,
  PaymentMethodBox,
  Alert,
  PaymentInformationWrapper,
  Flex,
  ButtonWrapper
} from './styles'
import useForm from './models/useForm'
import { usePaymentPeriod } from './models/usePaymentPeriod'
import usePaymentMethod from './models/usePaymentMehod'
import { PAYMENT_METHOD } from './constant'
import { formatMoney } from 'utils'
import PropTypes from 'prop-types'

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

const defaulProps = {
  location: {
    pathname: '/payment'
  }
}

const PaymentView = ({ location }) => {
  const intl = useIntl()
  const { selectedPackage, ...cart } = useSelector((state) => state.cart)
  const paymentPeriod = usePaymentPeriod()
  const { currency } = cart.detailCurrency
  // NOTE: refactor this with reusable utils
  const discountAmount = useMemo(() => cart.subTotal * (cart.discount / 100), [cart.discount, cart.subTotal])
  const calcVat = useMemo(() => (cart.subTotal - discountAmount)* (cart.vat / 100), [cart.subTotal, cart.vat, discountAmount])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_METHOD.CREDIT_CARD)
  const { 
    paymentMethod, 
    isLoading 
  } = usePaymentMethod()

  const cartRef = useRef()
  const refSubmitBtn = useRef()

  useEffect(() => {
    if(!selectedPackage.name) navigate('/pricing')
  }, [selectedPackage.name])

  const {
    isLoadingForm,
    values,
    ccHolderName,
    creditCardError,
    isSubmit,
    setValues,
    handleChange,
    handleChangeCountry,
    handleChangePhone,
    handleBlur,
    handleSubmit,
    setCCHolderName,
    setCreditCardError,
    setIsBlurCCInput
  } = useForm({ selectedPaymentMethod })

  const timelineList = {
    Checkout: {
      step: {
        onClick   : () => navigate('/checkout'),
        selected  : location.pathname.includes('/checkout'),
        completed :
          location.pathname.includes('/payment') ||
          location.pathname.includes('/payment/confirmation')
      },
      line: {
        active: location.pathname.includes('/payment')
      }
    },
    Payment: {
      step: {
        onClick   : () => navigate('/payment'),
        selected  : location.pathname.includes('/payment'),
        completed : location.pathname.includes('/payment/confirmation')
      },
      line: {
        active: location.pathname.includes('/payment/confirmation')
      }
    },
    Confirmation: {
      step: {
        onClick  : () => {},
        selected : location.pathname.includes('/payment/confirmation')
      }
    }
  }

  const handleChangePaymentMethod = useCallback((selectedPayment) => {
    const disabledVA = !paymentMethod.find((element) => element.name === PAYMENT_METHOD.VIRTUAL_ACCOUNT)

    if(selectedPayment === PAYMENT_METHOD.VIRTUAL_ACCOUNT && disabledVA) {
      setSelectedPaymentMethod(PAYMENT_METHOD.CREDIT_CARD)
    }
    else setSelectedPaymentMethod(selectedPayment)
  }, [paymentMethod])

  const buttonText = () => {
    if(selectedPaymentMethod === PAYMENT_METHOD.VIRTUAL_ACCOUNT) return intl.formatMessage({ id: '5f6a3eafb' })
    
    return `${intl.formatMessage({ id: 'af53583e0' })} ${ formatMoney(cart.subTotal - discountAmount + calcVat, { currency }) } ${intl.formatMessage({ id: '6fde6e224' })}`
  }

  return (
    <Wrapper>
      { /* Mobile */ }
      <MobileOnly>
        <Timeline
          menu={{
            list   : timelineList,
            margin : {
              m1 : '0.5rem 0 0 0.5rem',
              m2 : '0.5rem 0 0 1.5rem',
              m3 : '0.5rem 0.5rem 0 0'
            }
          }}
        />
      </MobileOnly>
      <MobilePaymentSummary periods={ paymentPeriod } />

      { /* Desktop left col view */ }
      <div>
        <DesktopOnly>
          <Timeline
            menu={{
              list   : timelineList,
              margin : {
                m1 : '0 0 0 1rem',
                m2 : '0 0 0 0.5rem',
                m3 : ''
              }
            }}
          />
        </DesktopOnly>

        <FormCard>
          <Title>
            { intl.formatMessage({ id: 'c64912dd0' }) }
          </Title>

          <Text fontSize='0.9rem' m='0.25rem 0 1rem'>
            { intl.formatMessage({ id: '9b2a68fb9' }) }
          </Text>

          {
            isLoadingForm 
              ? (
                <>
                  <Shimmer />
                  <Shimmer />
                  <Shimmer />
                  <Shimmer />
                  <Shimmer />                
                  <Shimmer />
                  <Shimmer />
                  <Shimmer />
                </>
              )
              : (
                <form 
                  autoComplete='off' 
                  onSubmit={ handleSubmit }
                >
                  { /* Billing Information */ }
                  <div>
                    <BillingInformationForm
                      values={ values }
                      setValues={ setValues }
                      handleChange={ handleChange }
                      handleChangeCountry={ handleChangeCountry }
                      handleChangePhone={ handleChangePhone }
                      handleBlur={ handleBlur }
                    />

                    <Alert
                      color='rgba(34, 43, 73, 0.1)'
                      margin='1rem 0 1.5rem'
                      marginMobile='0.75rem 0 1.5rem'
                    >
                      <Flex justifyContentCenter alignItemsCenter m='0.5rem 0'>
                        <IconInfo />
                        <Text 
                          m='0 0 0 1rem' 
                          marginMobile='0 0 0 1rem'
                          letterSpacing='0.29px'
                        >
                          { intl.formatMessage({ id: 'fad24c564' }) }
                        </Text>
                      </Flex>
                    </Alert>
                  </div>

                  { /* Payment Information */ }
                  <div>
                    <Text shaded fontWeight='600'>
                      { intl.formatMessage({ id: 'c542ef50d' }) }
                    </Text>

                    { 
                      isLoading 
                        ? null 
                        : (
                          <PaymentInformationWrapper>
                            <PaymentMethodBoxWrapper>

                              { /* CC option */ }
                              <PaymentMethodBox
                                isActive={ selectedPaymentMethod === PAYMENT_METHOD.CREDIT_CARD }
                                isDisabled={ !paymentMethod.find((element) => element.name === PAYMENT_METHOD.CREDIT_CARD) }
                                onClick={ () => handleChangePaymentMethod(PAYMENT_METHOD.CREDIT_CARD) }
                              >

                                {
                                  selectedPaymentMethod === PAYMENT_METHOD.CREDIT_CARD
                                    ? (
                                      <IconCreditCardWhite style={{ marginRight: '0.25rem' }} />
                                    ) : (
                                      <IconCreditCardDarkBlue style={{ marginRight: '0.25rem' }} />
                                    ) }
                                <span>
                                  { intl.formatMessage({ id: '9fb6683a0' }) }
                                </span>
                              </PaymentMethodBox>

                              { /* bank transfer option */ }
                              <PaymentMethodBox
                                isActive={ selectedPaymentMethod === PAYMENT_METHOD.VIRTUAL_ACCOUNT }
                                isDisabled={ !paymentMethod.find((element) => element.name === PAYMENT_METHOD.VIRTUAL_ACCOUNT) }
                                onClick={ () => handleChangePaymentMethod(PAYMENT_METHOD.VIRTUAL_ACCOUNT) }
                              >
                                { 
                                  selectedPaymentMethod === PAYMENT_METHOD.VIRTUAL_ACCOUNT 
                                    ? (
                                      <IconBankTransferWhite style={{ marginRight: '0.25rem' }} />
                                    ) 
                                    : (
                                      <IconBankTransferDarkBlue style={{ marginRight: '0.25rem' }} />
                                    ) 
                                }
                                <span>
                                  { intl.formatMessage({ id: 'bff6dbd1c' }) }
                                </span>
                              </PaymentMethodBox>

                            </PaymentMethodBoxWrapper>

                            {
                              selectedPaymentMethod === PAYMENT_METHOD.VIRTUAL_ACCOUNT 
                                ? (
                                  <BankTransfer paymentMethod={ selectedPaymentMethod }/>
                                ) : (
                                  <CreditCardForm
                                    creditCardError={ creditCardError }
                                    setCreditCardError={ setCreditCardError }
                                    handleBlur={ handleBlur }
                                    ccHolderName={ ccHolderName }
                                    setCCHolderName={ setCCHolderName }
                                    setIsBlurCCInput={ setIsBlurCCInput }
                                  />
                                ) }
                          </PaymentInformationWrapper>
                        ) 
                    }
                  </div>

                  <ButtonWrapper>
                    <Button
                      ref={ refSubmitBtn }
                      buttonType='warning'
                      style={{
                        width  : '100%',
                        height : '100%'
                      }}
                      type='submit'
                      isLoading={ isSubmit }
                    >
                      { buttonText() }
                    </Button>
                  </ButtonWrapper>
                </form>
              )
          }
        </FormCard>
      </div>
      <DesktopPaymentSummary periods={ paymentPeriod } ref={ cartRef } />
    </Wrapper>
  )
}

PaymentView.propTypes = propTypes
PaymentView.defaulProps = defaulProps

export default PaymentView
