import React from 'react'
import { IconLock } from '@qlue/icon-kit'
import { InputText } from 'components'
import { CreditCardFormWrapper, CardDetailWrapper, ErrorText, Col, CardInput } from './styles'
import { Alert, Text, Flex } from '../../styles'
import PropTypes from 'prop-types'
import { CardElement } from '@stripe/react-stripe-js'
import { useIntl } from 'react-intl'

const propTypes = {
  creditCardError    : PropTypes.string,
  setCreditCardError : PropTypes.func,
  handleBlur         : PropTypes.func,
  ccHolderName       : PropTypes.shape({
    value : PropTypes.string,
    error : PropTypes.string
  }),
  setCCHolderName  : PropTypes.func,
  setIsBlurCCInput : PropTypes.func
}

const defaultProps = {
  creditCardError    : '',
  setCreditCardError : () => {},
  handleBlur         : () => {},
  ccHolderName       : {
    value : '',
    error : ''
  },
  setCCHolderName  : () => {},
  setIsBlurCCInput : () => {}
}

const CreditCardForm = ({
  creditCardError,
  setCreditCardError,
  handleBlur,
  ccHolderName,
  setCCHolderName,
  setIsBlurCCInput
}) => {

  const intl = useIntl()

  return(
    <>
      <CreditCardFormWrapper>
        <CardDetailWrapper>
          <Text
            fontSize="0.875rem"
            fontSizeMobile="0.75rem"
            m="0.5rem 0 0.25rem"
            marginMobile="0 0 0.325rem"
          >
            { intl.formatMessage({ id: '4e1ee5e57' }) }
          </Text>

          <CardInput creditCardError={ creditCardError || '' }>
            <CardElement 
              id='stripe-cc-input'
              options={{
                style: {
                  base: {
                    fontSize: '0.875rem'
                  }
                },
                hidePostalCode: true
              }}
              onChange={ (e) => {
                if(e.error) {
                  setCreditCardError(e.error.message)
                }
                else {
                  if(e.complete) {
                    setCreditCardError('')
                  }
                  else {
                    setCreditCardError('Please complete all fields')
                  }
                          
                }
              } }
              onBlur={ () => setIsBlurCCInput(true) }
            />
          </CardInput>
          <ErrorText>
            { creditCardError }
          </ErrorText>
        </CardDetailWrapper>

        <Col>
          <InputText
            name="cardHolderName"
            title={ intl.formatMessage({ id: '159e39adf' }) }
            value={ ccHolderName.value }
            error={ ccHolderName.error }
            placeholder={ intl.formatMessage({ id: '4ef4f602a' }) }
            onChange={ (e) => {
              ccHolderName.value = e.target.value
              setCCHolderName({ ...ccHolderName })
            } }
            onBlur={ handleBlur }
          />
        </Col>
      </CreditCardFormWrapper>

      <Alert color="rgba(1, 134, 213, 0.15)" margin="1rem 0 0">
        <Flex justifyContentCenter alignItemsCenter>
          <IconLock />
          <Text m="0.5rem 0 0.5rem 1rem">
            <strong>{ intl.formatMessage({ id: '459f6d9e1' }) }</strong> { intl.formatMessage({ id: '4b57c5eea' }) }
          </Text>
        </Flex>
      </Alert>
    </>
  )
}

CreditCardForm.propTypes = propTypes
CreditCardForm.defaultProps = defaultProps

export default CreditCardForm
