import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { 
  Select, 
  DesktopOnly, 
  Button, 
  InputText 
} from 'components'
import { Text } from 'domains/Payment/styles'
import { 
  Wrapper, 
  Li, 
  Ul, 
  Hr, 
  Grid,
  Title
} from './styles'
import { formatMoney } from 'utils'
import { PaymentCalculation } from 'utils/paymentCalculation'

const DesktopPaymentSummary = forwardRef(({ periods }, ref) => {
  const intl = useIntl()

  const { 
    isAnnually,
    selectedPackage, 
    paymentPeriod, 
    isCustom, 
    ...cart 
  } = useSelector((state) => state.cart)
  const { currency } = cart.detailCurrency
  const keysProduct = Object.keys(selectedPackage.products)

  const Discount = () => {
    if(cart.discount > 0) return (
      <Text success>
        { 
          `- ${formatMoney(PaymentCalculation({
            subTotal : cart.subTotal,
            discount : cart.discount,
            vat      : cart.vat
          }).discountAmount, { currency }) }` 
        }
      </Text>
    )

    return (<Text fontSize="1rem" color="#222b49">-</Text>)
  }

  return (
    <DesktopOnly>
      <Wrapper ref={ ref }>
        <Title>{ intl.formatMessage({ id: '39e025b72' }) }</Title>
        <Hr />

        { keysProduct.length > 0 ? (
          <Ul>
            <Li>
              <Text bold>{ selectedPackage.name }</Text>
            </Li>
            { keysProduct.map((id) => (
              <Li key={ id }>
                <Text bold>{ selectedPackage.products[id].name }</Text>
                <Text>
                  { selectedPackage.products[id]?.product_account_package?.quantity }{ ' ' }
                  { selectedPackage.products[id].product_unit.name }
                </Text>
              </Li>
            )) }
          </Ul>
        ) : (
          <Text textAlign="center">{ intl.formatMessage({ id: '89d0c11c7' }) }</Text>
        ) }
        <Hr />

        <div>
          <Text shaded>{ intl.formatMessage({ id: '60f151225' }) }</Text>

          <Select
            key={ periods.selectedPeriod && (periods.selectedPeriod.name === 'Annually' || periods.selectedPeriod.name === 'Monthly') ? intl.formatMessage({ id: `${periods.selectedPeriod.name}-xth` }) : periods.selectedPeriod.name }
            selectedItem={ periods.selectedPeriod }
            onChange={ periods.handlePaymentPeriodChange }
            options={ periods.list }
            isLoading={ periods.isLoading }
            placeholder="Select payment period"
          />

          { 
            !isAnnually
              ? (
                <Text success>{ intl.formatMessage({ id: 'ea2f71ac4' }) }</Text>
              ) : null 
          }
          
          <Text.Referral 
            shaded
            m='1rem 0 0'
            style={{ textTransform: 'uppercase' }}
          >
            { intl.formatMessage({ id: '52cd63d2d' }) }
          </Text.Referral>

          <Grid>
            <InputText 
              disabled={ true }
              placeholder={ intl.formatMessage({ id: 'e3a34d9e2' }) }
              value={ cart.referralCode }
            />

            <Button 
              type='warning'
              disabled={ true }
            >
              { intl.formatMessage({ id: '62ff2374f' }) }
            </Button>
          </Grid>
        </div>

        <div>
          <Text shaded>{ intl.formatMessage({ id: '15a72c471' }) }</Text>

          <Grid>
            <Text 
              shaded 
              bold
            >
              { intl.formatMessage({ id: '3e88df800' }) }
            </Text>

            <Text 
              bold 
              fontSize="1rem" 
              color="#222b49"
            >
              { formatMoney(cart.subTotal, { currency }) }
            </Text>
          </Grid>

          <Grid>
            <Text            
              shaded
              bold
              style={{ textTransform: 'uppercase' }}
            >
              { intl.formatMessage({ id: '7cb5d176e' }) }
            </Text>
            <Discount />
          </Grid>

          <Grid>
            <Text shaded bold>
              VAT ({ cart.vat }%)
            </Text>

            <Text 
              bold 
              fontSize="1rem" 
              color="#222b49"
            >
              { formatMoney(PaymentCalculation({
                subTotal : cart.subTotal,
                discount : cart.discount,
                vat      : cart.vat
              }).vatAmount, { currency }) }
            </Text>
          </Grid>
        </div>
        <Hr />

        <div>
          <Grid>
            <Text shaded bold>
              { intl.formatMessage({ id: 'eb8239389' }) }
            </Text>

            <Text bold fontSize="1.5rem" color="#222b49">
              { formatMoney(PaymentCalculation({
                subTotal : cart.subTotal,
                discount : cart.discount,
                vat      : cart.vat
              }).grandTotalAmount, { currency }) }
            </Text>
          </Grid>
        </div>
      </Wrapper>
    </DesktopOnly>
  )
})

DesktopPaymentSummary.displayName = 'DesktopPaymentSummary'

export default DesktopPaymentSummary
