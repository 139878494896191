import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { MobilePaymentSummaryWrapper, Grid, Hr } from './styles'
import { Text } from 'domains/Payment/styles'
import { 
  Select, 
  MobileOnly, 
  InputText, 
  Button 
} from 'components'
import { formatMoney } from 'utils'
import { PaymentCalculation } from 'utils/paymentCalculation'

const MobilePaymentSummary = ({ periods }) => {
  const intl = useIntl()

  const { 
    isAnnually,
    paymentPeriod, 
    selectedPackage, 
    isCustom, 
    ...cart 
  } = useSelector((state) => state.cart)
  const { currency } = cart.detailCurrency

  const Discount = () => {
    if(cart.discount > 0) return (
      <Text success>{ 
        `- ${formatMoney(PaymentCalculation({
          subTotal : cart.subTotal,
          discount : cart.discount,
          vat      : cart.vat
        }).discountAmount, { currency }) }` 
      }
      </Text>
    )

    return (<Text fontSize="1rem" color="#222b49">-</Text>)
  }

  return (
    <MobileOnly>
      <MobilePaymentSummaryWrapper>
        <div>
          <Text shaded>{ intl.formatMessage({ id: '0afdbef7c' }) }</Text>

          <Text bold color="#222b49">
            { selectedPackage.name ? (
              selectedPackage.name
            ) : (
              <Text bold>{ intl.formatMessage({ id: '89d0c11c7' }) }</Text>
            ) }
          </Text>
        </div>

        <div>
          <Text shaded m="1rem 0 0">
            { intl.formatMessage({ id: '60f151225' }) }
          </Text>

          <Select
            key={ periods.selectedPeriod?.name }
            selectedItem={ periods.selectedPeriod }
            onChange={ periods.handlePaymentPeriodChange }
            options={ periods.list }
            isLoading={ periods.isLoading }
            placeholder="Select payment period"
          />

          { !isAnnually ? (
            <Text 
              success 
              m="1rem 0"
              textAlign='center'
            >
              {  intl.formatMessage({ id: 'ea2f71ac4' }) }
            </Text>
          ) : null }
        </div>

        <Text.Referral 
          shaded
          m='2rem 0 0'
          style={{ textTransform: 'uppercase' }}
        >
          { intl.formatMessage({ id: '52cd63d2d' }) }
        </Text.Referral>

        <Grid>
          <InputText 
            disabled={ true }
            placeholder={ intl.formatMessage({ id: 'e3a34d9e2' }) }
            value={ cart.referralCode }
          />

          <Button
            type='warning'
            disabled={ true }
          >
            { intl.formatMessage({ id: '62ff2374f' }) }
          </Button>
        </Grid>

        <div>
          <Text shaded>{ intl.formatMessage({ id: '15a72c471' }) }</Text>

          <Grid>
            <Text shaded bold>
              { intl.formatMessage({ id: '3e88df800' }) }
            </Text>
            <Text bold color="#222b49">
              { formatMoney(cart.subTotal, { currency }) }
            </Text>
          </Grid>

          <Grid>
            <Text 
              shaded 
              bold
              style={{ textTransform: 'uppercase' }}
            >
              { intl.formatMessage({ id: '7cb5d176e' }) }
            </Text>
            <Discount />
          </Grid>

          <Grid>
            <Text shaded bold>
              VAT ({ cart.vat }%)
            </Text>
            <Text bold color="#222b49">
              { formatMoney(PaymentCalculation({
                subTotal : cart.subTotal,
                discount : cart.discount,
                vat      : cart.vat
              }).vatAmount, { currency }) }
            </Text>
          </Grid>
          <Hr />

          <Grid>
            <Text shaded bold>
              { intl.formatMessage({ id: 'eb8239389' }) }
            </Text>
            <Text bold color="#222b49" fontSize="1rem">
              { formatMoney(PaymentCalculation({
                subTotal : cart.subTotal,
                discount : cart.discount,
                vat      : cart.vat
              }).grandTotalAmount, { currency }) }
            </Text>
          </Grid>
        </div>
      </MobilePaymentSummaryWrapper>
    </MobileOnly>
  )
}

export default MobilePaymentSummary
